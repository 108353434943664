<template>
  <div style="font-size: 13px" v-if="data">
    <el-card class="information">
      <p>{{ $t("purchase.basic") }}</p>
    </el-card>
    <el-card>
      <el-row>
        <el-col :span="8">
          {{ $t("purchase.productName") }}: {{ data.productName }}</el-col
        >
        <el-col :span="16"
          >{{ $t("purchase.category") }}: {{ data.categoryName }}</el-col
        >
      </el-row>
      <el-row class="mt">
        <el-col :span="8"
          >{{ $t("purchase.purchaseType") }}: {{ data.purchaseType }}</el-col
        >
        <el-col :span="16"
          >{{ $t("purchase.purpose") }}: {{ data.procurementPurpose }}</el-col
        >
      </el-row>
      <el-row class="mt">
        <el-col :span="8"
          >{{ $t("purchase.quantity") }}: {{ data.quantity }}</el-col
        >
        <el-col :span="16"
          >{{ $t("purchase.tradeTerms") }}: {{ data.tradeTerms }}</el-col
        >
      </el-row>
      <el-row class="mt">
        <el-col :span="8"
          >{{ $t("purchase.maximumBudget") }} : {{ data.maximunBudget }}</el-col
        >
      </el-row>
    </el-card>

    <el-input
      type="textarea"
      :autosize="{ minRows: 12, maxRows: 16 }"
      class="input"
      v-model="input"
      :placeholder="this.$t('resident.pleaseEnter')"
    ></el-input>

    <div class="mt">
      <p class="mt1">{{ $t("purchase.specify") }}</p>
      <p class="mt1">1. {{ $t("purchase.personal") }}</p>
      <p class="mt1">2. {{ $t("purchase.provide") }}</p>
      <p class="mt1">3. {{ $t("purchase.check") }}</p>
    </div>
    <div class="button">
      <el-button class="btn" @click="submit(data._id)"
        >{{ $t("login.submit") }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { getData, createData } from "../../helper/api";
export default {
  data() {
    return {
      input: "",
      data: [],
      id: "",
      storeId: "",
    };
  },
  created() {
    this.getData(this.$route.query.id);
    this.storeId = localStorage.getItem("storeId");
  },
  methods: {
    getData(id) {
      getData("GET", "purchase", id).then((res) => {
        console.log(res);
        this.data = res.data;
        this.id = res.data._id;
      });
    },
    submit(id) {
      if (this.input) {
        createData("POST", "purchaseReply", {
          purchaseId: this.id,
          storeId: this.storeId,
          chats: [
            {
              content: this.input,
            },
          ],
        }).then((res) => {
          console.log(res);
          this.$router.push({
            path: "/purchase/procurementDetail",
            query: {
              id
            },
          });
          // this.$router.push("/purchase/procurementDetail")
        });
      } else {
        this.$message({
          showClose: true,
          message: this.$t("purchase.quotationInformation"),
          type: "error",
        });
      }
    },
  },
};
</script>

<style scoped>
.information {
  font-size: 14px;
  font-weight: 700;
  color: #7c4919;
  background: #ffdcbc;
  border-radius: 15px 15px 0 0;
}
.input {
  background: #f2f2f2;
  margin-top: 30px;
}
.input .el-textarea__inner {
  font-family: "微软雅黑";
  padding-top: 20px;
  padding-left: 20px;
}
.button {
  text-align: center;
  margin-top: 50px;
}
.button .btn {
  width: 218px;
  height: 51px;
  color: #fff;
  background: #ff0a0a;
  font-size: 20px;
  border-radius: 15px;
}
</style>